import PropTypes from 'prop-types';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import CTA from '../../components/global/cta';
import GetInTouch from '../../components/global/get-in-touch';
import { buildImageObj } from '../../lib/helpers';
import { imageUrlFor } from '../../lib/image-url';
import PortableText from '../../components/portableText';
import Container from '../global/container';
function Team(props) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.25
  });

  const { _rawContent, name, role, image } = props;

  return (
    <Container>
      <article className="team" ref={ref}>
        <div className="site-wide">
          <GetInTouch top />
          <div className="grid-container grid-container--half">
            <div className="grid-column copy">
              <h1>{name}</h1>
              <h2 className="h3-styling">{role}</h2>
              {_rawContent && <PortableText blocks={_rawContent} />}
            </div>
            <div className="grid-column image">
              <img
                src={imageUrlFor(buildImageObj(image.image))
                  .auto('format')
                  .url()}
                alt={image.alt}
              />
            </div>
          </div>
        </div>
      </article>
    </Container>
  );
}

Team.propTypes = {
  _rawContent: PropTypes.array,
  name: PropTypes.string,
  role: PropTypes.string,
  image: PropTypes.object
};

export default Team;
