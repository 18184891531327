import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { mapEdgesToNodes, toPlainText } from '../lib/helpers';
import Container from '../components/global/container';
import TeamLargeCardList from '../components/team/team-large-card-list';
import GraphQLErrorList from '../components/graphql-error-list';
import Team from '../components/team/team';
import SEO from '../components/global/seo';
import Layout from '../containers/layout';
import Header from '../components/global/header';

export const query = graphql`
  query TeamDetailTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawInsightscopy
      _rawTeamcopy
    }
    post: sanityTeam(id: { eq: $id }) {
      id
      order
      name
      role
      bgColour {
        hex
      }
      seo {
        title
        keywords
        description
        image {
          image {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
          }
        }
      }
      image {
        alt
        image {
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
        }
        video {
          asset {
            _id
          }
        }
      }
      image2 {
        alt
        image {
          hotspot {
            _key
            _type
            height
            width
            x
            y
          }
          asset {
            _id
          }
          crop {
            top
            right
            left
            bottom
            _type
            _key
          }
        }
        video {
          asset {
            _id
          }
        }
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
    }
    team: allSanityTeam {
      edges {
        node {
          id
          name
          slug {
            current
          }
          image2 {
            alt
            image {
              hotspot {
                _key
                _type
                height
                width
                x
                y
              }
              asset {
                _id
              }
              crop {
                top
                right
                left
                bottom
                _type
                _key
              }
            }
            video {
              asset {
                _id
              }
            }
          }
        }
      }
    }
  }
`;

const TeamDetailTemplate = (props) => {
  const { data, errors } = props;
  const post = data && data.post;
  const team = (data || {}).team ? mapEdgesToNodes(data.team) : [];
  const site = (data || {}).site;
  return (
    <>
      <Header headerStyle="clear" />
      <Layout>
        {errors && <SEO title="GraphQL Error" />}
        {post.seo && (
          <SEO
          title={post.seo.title !== null ? post.seo.title : false}
          description={post.seo.description !== null ? post.seo.description : false}
          keywords={post.seo.keywords !== null ? post.seo.keywords : false}
          image={post.seo.image !== null ? post.seo.image : false}
        />
        )}

        {errors && (
          <Container>
            <GraphQLErrorList errors={errors} />
          </Container>
        )}

        {post && <Team {...post} />}

        {team && <TeamLargeCardList nodes={team} cta bgColour="#E9E8E8" copy={site} />}
      </Layout>
    </>
  );
};

TeamDetailTemplate.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object
};

export default TeamDetailTemplate;
